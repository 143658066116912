/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"

import Sidebar from "./sidebar"

const Layout = ({ children }) => {
  const [sidebarStatus, setSidebarStatus] = useState("")

  const onToogle = () => {
    if (sidebarStatus === "closed" || sidebarStatus === "") {
      setSidebarStatus("opened")
    } else {
      setSidebarStatus("closed")
    }
  }

  return (
    <>
      <div className="row no-gutters">
        <div className={`sidebar ${sidebarStatus} col-md-3 col-lg-3`}>
          <Sidebar />
        </div>
        <div className="col col-xs-12 col-sm-12 col-md-9 col-lg-9">
          <div className={`sidebar-toggle pl-3 ${sidebarStatus}`}>
            <button onClick={onToogle}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </button>
          </div>
          <main>{children}</main>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
