import React from "react"
import TwitterLogo from "../images/twitter.png"
import GitHubLogo from "../images/github.png"
import Logo from "../images/logo-color.png"
import { Link } from "gatsby"

const Sidebar = () => {
  return (
    <>
      <div className="sidebar--header">
        <span className="title">
          <img className="img-fluid" alt="" src={Logo} />
        </span>
      </div>
      <div className="sidebar--links">
        <ul>
          <li>
            <Link activeClassName="active" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/about-me">
              About Me
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/skills">
              Skills
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/education">
              Education
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/blog">
              Blog
            </Link>
          </li>
        </ul>
      </div>
      <div className="sidebar--footer">
        <div className="__footer--social-links">
          <ul>
            <li>
              <a
                rel="noopener noreferrer"
                href="https://twitter.com/_kalai03"
                rel="noopener"
                target="_blank"
              >
                <img alt="Twitter" src={TwitterLogo} />
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href="https://github.com/kalaivanan-muthusamy"
                rel="noopener"
                target="_blank"
              >
                <img style={{ padding: "5px" }} alt="Github" src={GitHubLogo} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Sidebar
